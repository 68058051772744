<template>
    <v-container>
        <v-card>
                  <v-row align-content="center" style="margin: 0;"
                  > 
                    <v-col class="col-6 "  offset-md="3">
                <v-card >
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span> 
                    </v-card-title>
  
                    <v-card-text>
                      <v-container> 
                        <v-row> 
                          
                          <v-col
                            cols="12"
                            sm="12"
                          > 
                          <v-file-input
                            v-model="files"
                            truncate-length="1"
                            label="Selecciona el documento"
                            show-size
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          prepend-icon="mdi-file-excel"
                            >
                        </v-file-input>
                          </v-col> 

                        </v-row>
                         
                      </v-container>
                    </v-card-text>
  
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="limpiar"
                        :disabled = "isLoading"
                      >
                        Limpiar
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="procesarDocumento"
                        :loading="isLoading"
                      >
                        Procesar
                      </v-btn>
                    </v-card-actions>
                    <v-row> 
                           
                          <v-text-field disabled class="col-6 " v-model="btotal" label="Base total"></v-text-field> 
                          
                          <v-text-field disabled class="col-6 " v-model="bvalidos"  label="Base validos"></v-text-field> 
                          
                        </v-row>
                         
                  </v-card></v-col> 
                  </v-row>
                </v-card>
    </v-container>
   
  </template>
  <script>
  import ApiService from '../../service/apis'
  //import { readExcel } from "@/plugins/importExcel"; 
  import * as XLSX from 'xlsx';
  //import moment from 'moment'    
  export default {
    name:'Correos',
    data: () => ({
        btotal:0,
        bvalidos:0,
        excelCorreos :null,
        files:null,
        disableBtnAddHsm : true,
        nombreDocumento : '' ,
        dialog: true,
        dialogDelete: false,
        selected: [{}],
        isLoading:false,
        isLoadingDeleteBtn : false,
        sesion:JSON.parse(sessionStorage.getItem('sesion')),
        search:null,
        textAlertModals:'',
        alertaPaso1:false, 
        alertaTypo : 'success', 
        typeMedia : '',
        modelTipo:null,
        alertaModalsColor : '',
        alertaModals : false,  
        documents :[],
        headers: [],
        blacklists: [],
        editedIndex: -1,
        show1:false,
        validos:[],
        editedItem: {
          id: 0,
          status: 1,
          senderid: '',
          comentario:''
        },
        defaultItem: {
          id: 0,
          status: 1,
          senderid: '',
          comentario:''
        },
        loading:false
      }),
      computed: {
        formTitle () {
          return this.editedIndex === -1 ? 'Limpieza de base de correos'  : 'Documentos' 
        },
      },
      watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
      },
      created () {
        //this.seleccionAsistente()
       // this.getDocumentos()
      },
      updated(){
        //this.evento()
      },
      methods: {
        limpiar(){
            this.isLoading=false 
            this.btotal=0
            this.bvalidos =0
            this.files = null
        },
      procesarDocumentoBack(){
        this.isLoading=true

        if(this.files !=null){
            let formData = new FormData()
            formData.append("files", this.files) 
            ApiService.limpiarCorreos(formData).then((resp)=>{
                let arr=resp.validos 
                const p = arr[0] 
                let params=Object.getOwnPropertyNames(p)
            // params.shift()
                let data = XLSX.utils.json_to_sheet( arr,{header:params})
                const Newworkbook = XLSX.utils.book_new()  
                XLSX.utils.book_append_sheet(Newworkbook, data, 'newexcel')
                XLSX.writeFile(Newworkbook, `newexcel.xlsx`)

                this.btotal=resp.datos.base
                this.bvalidos =resp.datos.validos
                
            }).finally(()=>{
                this.isLoading=false 
            })
        }else{
            this.isLoading=false 
        }
        
      },
      procesarDocumento() {    
        this.isLoading= true
        let arr = []

        if(this.files !=null){
            let fileReader = new FileReader()
            fileReader.readAsBinaryString(this.files)
            fileReader.onload = (event)=>{
            let data = event.target.result;
            let workbook = XLSX.read(data,{type:"binary"})

            workbook.SheetNames.forEach(sheet => {
                  let rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]) 
                  rowObject.map((validar) => {
                    // eslint-disable-next-line
                    if(/^(([^*+´<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(validar.email)){  
                      //  arr.push(validar)  
                        if(!arr.some(r=> r.email == validar.email) && validar.email.split('@')[0]!=validar.celular && validar.email.split('@')[0].match(/\D/)!=null){
                            //validar.email = validar.email.toUpperCase()
                            arr.push(validar)  
                        }
                    }  
                  })
                  /* arr.filter((item,index)=>{
                        return arr.indexOf(item) === index;
                    })    */
                    const p = arr[0] 
                  let params=Object.getOwnPropertyNames(p)
                    params.shift()
                  let data = XLSX.utils.json_to_sheet( arr,{header:params})
                  const Newworkbook = XLSX.utils.book_new()  
                    XLSX.utils.book_append_sheet(Newworkbook, data, 'newexcel')
                    XLSX.writeFile(Newworkbook, `newexcel.xlsx`)
                    this.isLoading=false
                    this.btotal=rowObject.length
                   this.bvalidos =arr.length 
            })
            }
        }else{
            this.isLoading=false 
        }

          
      },  
      },
  }
  </script>
  <style>
  .v-application .headline {
    letter-spacing: unset;
  }
  </style>