import api from './apiHSM'
let service = {}
let config = {
  headers: {
  "Content-Type": "multipart/form-data"
  }
}



service.uploadFile = function (formData) {
    return api.post('api/envioExcel', formData, config).then(res => res.data)
}
service.subirArchivo = function (formData) {
    return api.post('api/uploadExcel', formData, config).then(res => res.data)
}
service.getHsms = function(origen) {
    return api.get('/api/catalogohsm/'+origen).then(res => res.data)
}
service.guardarHsm = function(data) {
    return api.post('/api/hsm',data).then(res => res.data)
}
service.actualizarHsm = function(data) {
    return api.put('/api/hsm',data).then(res => res.data)
}
service.actualizarStatusHsm = function(data) {
    return api.put('/api/hsm/status',data).then(res => res.data)
}
service.getAllHsm = function(origen) {
    return api.get('/api/gethsm/1/'+origen).then(res => res)
}
service.getByFilterHsm = function(filtro) {
    return api.get('/api/gethsm?id='+filtro).then(res => res)
}
service.generarEnvio = function (data) {
    return api.post('api/generarenvio', data).then(res => res.data)
}
service.validarEnvio = function (data) {
    return api.post('api/validarenvio', data).then(res => res.data)
}
service.getEnvios = function(filtro,origen) {
    return api.get('/api/envios/'+filtro+'/'+origen).then(res => res.data)
}
service.getEnvio = function(filtro,origen) {
    return api.get('/api/envio/'+filtro+'/'+origen).then(res => res.data)
}
service.enviosReporte = function(fecha_inicial, fecha_final) {
    return api.get('/api/envios?' +`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
service.enviosReporte2 = function(envio) {
    return api.get('/detalleenvio/'+envio).then(res => res.data)
}
service.reporteEnvios = function(fecha_inicial, fecha_final) {
    return api.get('/api/reporte/envios?'+`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
service.reporteEnviosHemodialisis = function(fecha_inicial, fecha_final) {
    return api.get('/api/reporte/hemodialisis/envios?'+`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
service.reporteEnviosSalvador = function(fecha_inicial, fecha_final) {
    return api.get('/api/reporte/salvador/envios?'+`fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`).then(res => res.data)
}
service.reporteLinks = function(envio) {
    return api.get('/api/reporte/links?envio='+envio).then(res => res.data)
}
service.getCampanas = function(origen) {
    return api.get('/api/campanas/'+origen).then(res => res.data)
}

service.nuevaCampana = function(data) {
    return api.post('/api/campana',data).then(res => res.data)
}

service.actualizarCampana = function(data) {
    return api.put('/api/campana',data).then(res => res.data)
}

service.getFolioGenericoCampana = function(campana_id,origen) {
    return api.get('/api/campana/foliogenerico?'+'campana_id='+campana_id+'&origen='+origen).then(res => res.data)
}

service.guardarFolioGenerico = function(data) {
    return api.post('/api/campana/foliogenerico',data).then(res => res.data)
}

service.guardarFolioEstudiosExcel = function(data) {
    return api.post('/api/campana/foliosestudios', data, config).then(res => res.data)
}

service.getFolioEstudiosCampana = function(campana_id,origen) {
    return api.get('/api/campana/folioestudios?'+'campana_id='+campana_id+'&origen='+origen).then(res => res.data)
}

service.addBlacklists = function(data) {
    return api.post('/api/blacklist/add',data).then(res => res.data)
}

service.updateBlacklists = function(data) {
    return api.put('/api/blacklist/update',data).then(res => res.data)
}

service.getBlackLists = function(origen) {
    return api.get('/api/blacklist/'+origen).then(res => res.data)
}

service.getDocumentos = function() {
    return api.get('/api/documentos').then(res => res.data)
}

service.limpiarCorreos = function (formData) {
    return api.post('/api/uploadEmails', formData, config).then(res => res.data)
}

service.getLinks = function (capturista_id) {
    return api.get('/sd/links/'+ capturista_id).then(res => res.data)
}

service.getByPalabra = function (palabra_clave) {
    return api.get('/sd/'+palabra_clave).then(res => res.data)
}

service.crearLink = function (formData) {
    return api.post('/sd/create', formData).then(res => res.data)
}

service.updateLink = function (formData) {
    return api.put('/sd/update', formData).then(res => res.data)
}

service.getEnvioWhatsapp = function (envioId, origenId) {
    return api.post('api/reporte/enviosWhatsapp', {
        envio_id: envioId,
        origen: origenId
    }).then(res => res.data);
};
export default service